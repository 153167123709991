/**
 * This is a SearchL Component.This Component is used to render the Search and the Auto Suggest Component on L Screen. This Component will open the Search Suggestion on click of Search Icon.
 *
 * @module views/components/SearchL
 * @memberof -Common
 */
import './SearchL.scss';

import React, { useRef, useState } from 'react';

import { FocusScope } from '@react-aria/focus';
import classNames from 'classnames';
import { Form, Formik } from 'formik';

import Button from '@ulta/core/components/Button/Button';
import InputFieldUnderline from '@ulta/core/components/InputFieldUnderline/InputFieldUnderline';
import UseKeyPress from '@ulta/core/hooks/useKeyPress/UseKeyPress';
import { useKeyPressCounter } from '@ulta/core/hooks/useKeyPressCounter/useKeyPressCounter';
import useOutsideClick from '@ulta/core/hooks/useOutsideClick/useOutsideClick';
import constants from '@ulta/core/utils/constants/constants';

import { useSearchHeaderContext } from '@ulta/providers/SearchHeaderProvider/SearchHeaderProvider';

import AutoSuggestDesktop from '../AutoSuggestDesktop/AutoSuggestDesktop';
import * as utils from './SearchL';
/**
  * Represents a SearchL component
  *
  * @method
  * @returns SearchL
  */
export const SearchL = function(){
  const [showAutoSuggest, setShowAutoSuggest] = useState( false );
  const wrapperRef = useRef();
  const inputRef = useRef();

  const {
    ariaLabel,
    screenReaderText,
    clearAccessibilityLabel,
    closePanel,
    handleSubmit,
    inputChangeHandler,
    invokeTypeAheadAction,
    loading,
    resetAction,
    moveCursorToEndOfInput,
    savedSearchTerm,
    searchInputLabel,
    setKeyBoardUser,
    submitSearchLabel,
    suggestions
  } = useSearchHeaderContext();

  // Close panel on outside click
  useOutsideClick( { watcher: showAutoSuggest, el: wrapperRef?.current }, { handleClick: () => {
    setShowAutoSuggest( false );
  } } );

  // Close panel on ESC key
  UseKeyPress( [constants.ESC_KEY], wrapperRef, () => {
    setShowAutoSuggest( false );
  }, [constants.ESC_KEY], false );

  // Activate ADA mode after 3 tabs detected
  useKeyPressCounter( { keyCodes: [constants.TAB_KEY], threshold: 3 }, { onThreshold: () => {
    setKeyBoardUser( true );
  } } );

  const displayPanel = suggestions?.length > 0;

  return (
    <div className='SearchL'
      ref={ wrapperRef }
      { ...( showAutoSuggest && { 'role':'region' } ) }
      { ...( showAutoSuggest && { 'aria-describedby':'searchDescription' } ) }
    >
      <div className='SearchL__searchIcon'>
        <Button
          variant='tertiary'
          icon
          iconImage='search'
          iconSize='xl'
          ariaLabel={ ariaLabel }
          ariaHiddenIcon={ true }
          onClick={ () => {
            invokeTypeAheadAction( savedSearchTerm );
            setShowAutoSuggest( true );
          } }
          className='SearchL--button'
          ariaControls='search-content'
          isExpanded={ showAutoSuggest }
        />
      </div>

      { showAutoSuggest && (
        <FocusScope
          contain={ showAutoSuggest }
          group='searchPanel'
          restoreFocus={ true }
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={ true }
        >
          <div open={ showAutoSuggest }
            className='SearchL__searchBar'
            id='search-content'
          >
            <Formik
              initialValues={ { search: savedSearchTerm } }
              onSubmit={ handleSubmit }
            >
              { () => (
                <Form className={ classNames( 'SearchL__form', {
                  'SearchL__form--loading': loading
                } ) }
                role='search'
                >
                  <p className='sr-only'
                    id='searchDescription'
                  >
                    { screenReaderText }
                  </p>
                  <InputFieldUnderline
                    ariaDescribedBy='searchDescription'
                    ref={ inputRef }
                    type='search'
                    name='search'
                    autoComplete='off'
                    leftIcon='Search'
                    onChange={ inputChangeHandler }
                    handleFocus={ () => {
                      moveCursorToEndOfInput( inputRef );
                    } }
                    handleResetVal={ resetAction }
                    placeholder={ searchInputLabel }
                    displayGoButton={ true }
                    clearValueText={ clearAccessibilityLabel }
                    submitText={ submitSearchLabel }
                    handleSubmit={ ( event ) => utils.processSubmit( { event }, { handleSubmit, setShowAutoSuggest } ) }
                  />
                </Form>
              ) }
            </Formik>
            <form
              className='SearchL__closeContainer'
            >
              <Button
                icon
                variant='unstyled'
                iconImage='X'
                iconSize='lg'
                ariaLabel={ closePanel }
                className='SearchL__searchBarClose'
                onClick={ () => {
                  setShowAutoSuggest( false );
                } }
                type='submit'
              />
            </form>
            { displayPanel && <AutoSuggestDesktop /> }

          </div>
        </FocusScope>
      ) }
    </div>
  );
};

/**
 * Handles the processSubmit function and setting setShowAutoSuggest to false.
 *
 * @param {Object} data - An object contains the event.
 * @param {Object} methods - An object contains handleSubmit and setShowAutoSuggest.
 */
export const processSubmit =  ( data, methods ) => {
  const { event } =  data ;
  const { handleSubmit, setShowAutoSuggest }  = methods;
  if( !handleSubmit || !setShowAutoSuggest || event.type !== 'submit' ){
    return;
  }
  handleSubmit( event );
  setTimeout( () => {
    setShowAutoSuggest( false );
  }, 0 );

};
export default SearchL;
