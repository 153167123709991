/**
 * @typedef {Object} STORAGE_KEY
 * @property {string} layerhostDatacapturePostProcess - stores Datacapture meta objects for processing on the next response, will be merged by tealium.js
 * @property {string} postLoginFavorite - stores a SKU that should be favorited after the user completes the login process
 * @property {string} plpFilterPersist - stores the state of the PLP filter drawer being open / closed
 * @property {string} typeAheadDatacapture - stores Datacapture for processing type ahead search events
 * @property {string} typeAheadRecentSearches - stores recent searches the user has executed
 * @property {string} typeAheadSavedSearchTerm - stores most recent search term the user has executed
 * @property {string} user - stores the user session object
 * @property {string} userLegacySessionData - stores some a legacy STK token under the 'sessionData' key for backwards compatibility
 * @property {string} amityPrompt - stores the time when anonymous users were last prompted
 */
export const STORAGE_KEY = {
  enablePersistentSearch: 'ULTA_ENABLE_PERSISTENT_SEARCH',
  layerhostDatacapturePostProcess: 'ULTA_SESSION_PIPELINE',
  productFilterDrawerPersist: 'ULTA_FILTER_PERSISTENCE',
  postLoginFavorite: 'ULTA_POST_LOGIN_FAVORITE_KEY',
  selectedStoreFromFilters: 'ULTA_SELECTED_STORE_FROM_FILTERS',
  recentlyViewedSKUs: 'ULTA_RECENTLY_VIEWED_SKU',
  typeAheadDatacapture: 'ULTA_TYPEAHEAD_DATACAPTURE',
  typeAheadRecentSearches: 'ULTA_RECENT_SEARCHES',
  typeAheadSavedSearchTerm: 'ULTA_SAVED_SEARCH_TERM',
  typeAheadRedirect: 'ULTA_TYPEAHEAD_REDIRECT',
  user: 'DSOTF_LOGIN_HINT_KEY',
  userLegacySessionData: 'sessionData',
  ttlStorage: 'ULTA_TTL_STORAGE',
  amityPrompt: 'ULTA_AMITY_PROMPT',
  storePersist: 'ULTA_STORE_PERSIT'
};

/**
 * @typedef {Object} SESSION_DATA_KEY - Houses keys used on the legacy `sessionData` object
 * @property {string} bdayPromoSeen - Whether or not the user has seen their bday promo
 * @property {string} appBannerSeen - Whether or not we've teased the mobile app banner download promo
*/
export const SESSION_DATA_KEY = {
  'bdayPromoSeen': 'birthday-promo-seen',
  'appBannerSeen': 'appBannerSeen'
};