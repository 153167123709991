/**
 * The layerhost acts at the gateway between the client application and the DXL layer.
 *
 * It's sole responsibilities are providing data context to it's children as well as
 * exposing methods for sending and receiving data to the DXL
 *
 * Page and component request flow
 *
 * 1. Component requests an update via invokeAction
 * 2. Sets skip to false so apollo query can run
 * 3. Triggers a DXL call
 * 4. When the response is received we either:
 *    4a. Update the entire page
 *    4b. Update the local component that layer host wraps
 *
 * For page updates:
 * 1. Broadcast message is sent to PageDataProvider that sets a new page context
 *    1a. Determines if a it's a page request by diffing the id of the response vs local component id
 *    1b. Sets new page props/meta
 *    1c. Sets page last updated timestamp
 *        -> We should do this atomically by setting a flag when making the request
 *        -> This can be a pass through from DXL, components shouldn't be responsible for making the distinction
 * 2. Props trickle down to child components
 *    2a. We do this by diffing component's local update timestamp vs. page last updated timestamp
 *
 * For local component updates:
 * 1. Local state updates happen at this level when response id matches local id
 *    1a. Sets new local props/meta
 *    1b. Sets component's last updated timestamp
 *
 * @module views/__core/LayerHost
 * @memberof -Common
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import IncrementalModule from '@ulta/core/components/IncrementalModule/IncrementalModule';
import * as utils from '@ulta/core/components/LayerHost/LayerHost';
import { OVERLAY_CONTAINER_MODULE_NAME } from '@ulta/core/components/OverLayContainer/OverLayContainer';
import { PAGE_MODULE_NAME } from '@ulta/core/components/Page/Page';
import Spacer from '@ulta/core/components/Spacer/Spacer';
import * as dxl from '@ulta/core/hooks/useDXLQuery/useDXLQuery';
import { useLayerHostAction } from '@ulta/core/hooks/useLayerHostAction/useLayerHostAction';
import { useLayerHostMeta } from '@ulta/core/hooks/useLayerHostMeta/useLayerHostMeta';
import useLoader from '@ulta/core/hooks/useLoader/useLoader';
import { useEventContext } from '@ulta/core/providers/EventProvider/EventProvider';
import { useLayerHostContext } from '@ulta/core/providers/LayerHostProvider/LayerHostProvider';
import { isOverlay } from '@ulta/core/providers/OverlayProvider/OverlayProvider';
import { decorateModules, usePageDataContext } from '@ulta/core/providers/PageDataProvider/PageDataProvider';
import { useUserContext } from '@ulta/core/providers/UserContextProvider/UserContextProvider';
import { hasItems } from '@ulta/core/utils/array/array';
import { constants } from '@ulta/core/utils/constants/constants';
import datacapture from '@ulta/core/utils/datacapture/datacapture';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';
import { devLogger } from '@ulta/core/utils/devMode/devMode';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';
import { setStorage } from '@ulta/core/utils/storage/storage';
import { isFunction, isSafeNumber } from '@ulta/core/utils/types/types';

import { STORAGE_KEY } from '../../utils/storageKeys/storageKeys';

// Move to core.js?
// Make an API or helper to abstract this? `addToSSRRenderStack( { moduleName, id } )`
if( isServer() ){
  global.layerHostRenderStack = [];
}

/**
 * Represents a LayerHost component
 *
 * @method
 * @param {LayerHostProps} parentProps - React properties passed from composition
 * @returns LayerHost
 */
export const LayerHost = function( parentProps ){
  /* Destructure props and request context dependencies
   */
  const { Content, spacerValue, anchorID, enableSpacer } = parentProps;
  const { broadcast, onBroadcast, getBroadcastCache, isMutationInFlight, setIsMutationInFlight } = useLayerHostContext();
  const { setPageData, pageLastUpdated, DONOTUSE_broadcastInitAction } = usePageDataContext();
  const { publishEvent, hasEvent, subscribe, composeOnEvent, unsubscribe } = useEventContext();
  const { setStore } = useUserContext();

  /* Prepare our stateful variables
   */
  const moduleRef = useRef();
  const [invokeQuery, setInvokeQuery] = useState( null );
  const [invokeMutation, setInvokeMutation] = useState( null );
  const [moduleContentId, setModuleContentId] = useState( null );
  const [localProps, setLocalProps] = useState( parentProps );
  const [nextProps, setNextProps] = useState( null );
  const [meta, setMeta] = useState( localProps.meta );
  const [loading, setLoading] = useState( null );
  const [pendingPageUpdate, setPendingPageUpdate] = useState( false );
  const [error, setError] = useState( null );
  const [isPageLoader, setIsPageLoader] = useState( false );
  const metaLastUpdated = useRef( 0 );
  const actionAfterRef = useRef( null );

  /* Define spacer value
   * - Spacer is enabled by default unless explicitly set to false
   */
  const hasSpacerValue = enableSpacer && !!spacerValue;
  const [showSpacer, setShowSpacer] = useState( hasSpacerValue );

  useEffect( () => {
    setShowSpacer( hasSpacerValue );
  }, [hasSpacerValue] );

  /* Setup prop comparison handler.
   * - Handles choosing between parent and local props when a page/parent update occurs.
   */
  useEffect( () => {
    utils.propComparisonHandler(
      { localProps, parentProps, pageLastUpdated, metaLastUpdated, pendingPageUpdate },
      { setLoading, setLocalProps, setNextProps, setMeta, setPendingPageUpdate }
    );
  }, [localProps, parentProps, pageLastUpdated] );

  /* Setup broadcast handler
   */

  const id = localProps.id;
  const onBroadcastCallback = useCallback(
    utils.composeOnBroadcastCallback( { id }, { setMeta, setLoading, setLocalProps } ),
    [id, setMeta, setLoading, setLocalProps]
  );
  /** no dependcies for useEffect to handle broadcast for a StateWrapper with empty modules rerendering scenarios */
  useEffect( () => {
    getBroadcastCache( { id }, { callback: onBroadcastCallback } );
  }, [] );
  useEffect( () => {
    // onBroadcast returns a cleanup function for the useEffect unmount
    const cleanup = onBroadcast( { id, moduleName }, { callback: onBroadcastCallback } );

    return () => {
      cleanup();
    };
  } );

  /* Setup `useLayerHostMeta`
   * This needs to be below the initial query setup for prequels so that we can pass `prequelLoading`
   * into the meta handler.
   *
   * - Handles processing the snackbar.
   * - Handles processing search results/typeahed data capture.
   */
  useLayerHostMeta( {
    meta,
    props: localProps,
    loading: parentProps.loading || localProps.loading || loading
  } );

  /* Setup `useLayerHostAction`
   * - Listens for invokeAction/invokeMutation calls.
   * - Handles initAction for modules.
   * - Processes actions passed in from invokeAction/invokeMutation.
   * - Automatically parses required moduleParams.
   * - Handles setting `skip` for Apollo query, which determines if we need to make a query request or not
   */
  const [mutation, query] = useLayerHostAction( {
    id: parentProps.id,
    invokeQuery,
    invokeMutation,
    meta,
    containerLayerHostContentId: parentProps.containerLayerHostContentId,
    props: nextProps || localProps
  } );

  /* Request helper for queries
   */
  const [actionQuery, { loading: queryLoading, data: queryData, error: queryError }] = dxl.useDXLQuery(
    query.graphql,
    query.config,
    true,
    `${localProps.moduleName} - Query`
  );

  useEffect( () => {
    utils.handleAction(
      { action: query, actionAfterRef, isMutation: false, isMutationInFlight },
      { actionHandler: actionQuery, setAction: setInvokeQuery, setIsMutationInFlight, setIsPageLoader }
    );
  }, [query.graphql, query.variables] );

  /* Response handler for queries
   */
  useEffect( () => {
    utils.handleDXLResponse(
      {
        actionAfterRef,
        props: parentProps,
        loading: queryLoading,
        data: queryData,
        error: queryError,
        metaLastUpdated,
        isMutation: false
      },
      {
        broadcast,
        DONOTUSE_broadcastInitAction,
        setLoading,
        setData: setLocalProps,
        setMeta,
        setError,
        setModuleContentId,
        setPageData,
        setNextProps,
        setPendingPageUpdate,
        setStore
      }
    );
  }, [queryLoading, queryData, queryError] );

  /* Request helper for invoking a mutation
   */
  const [actionMutation, { loading: mutationLoading, data: mutationData, error: mutationError }] = dxl.useDXLQuery(
    mutation.graphql,
    mutation.config,
    false,
    `${localProps.moduleName} - Mutation`
  );

  useEffect( () => {
    utils.handleAction(
      { action: mutation, actionAfterRef, isMutation: true, isMutationInFlight },
      { actionHandler: actionMutation, setAction: setInvokeMutation, setIsMutationInFlight, setIsPageLoader }
    );
  }, [mutation.graphql, mutation.variables] );

  /* Response handler for mutations
   */
  useEffect( () => {
    utils.handleDXLResponse(
      {
        actionAfterRef,
        props: parentProps,
        loading: mutationLoading,
        data: mutationData,
        error: mutationError,
        metaLastUpdated,
        isMutation: true
      },
      {
        broadcast,
        DONOTUSE_broadcastInitAction,
        setLoading,
        setData: setLocalProps,
        setMeta,
        setError,
        setModuleContentId,
        setPageData,
        setNextProps,
        setPendingPageUpdate,
        setIsMutationInFlight,
        setStore
      }
    );
  }, [mutationLoading, mutationData, mutationError] );

  /* Container query - resize listener setup
   */
  const [elementWidth, setElementWidth] = useState( null );
  const [displayAsMobile, setDisplayAsMobile] = useState( null );
  const [containerQuery, setContainerQuery] = useState( null );

  useEffect( () => {
    if( loading === true ){
      return;
    }

    const handleElementWidthCallback = () =>
      utils.handleElementWidth( { moduleRef: moduleRef.current }, { setElementWidth } );

    if( containerQuery && moduleRef.current ){
      global.addEventListener( 'resize', handleElementWidthCallback );

      if( !elementWidth ){
        handleElementWidthCallback();
      }
    }

    return () => {
      global.removeEventListener( 'resize', handleElementWidthCallback );
    };
  }, [containerQuery, moduleRef.current, loading] );

  /* Container query
   * - On the hosted component, set displayAsMobile=true when elementWidth < containerQuery.maxWidth
   */
  useEffect( () => {
    if( elementWidth && containerQuery?.maxWidth ){
      utils.handleSetDisplayAsMobile(
        { elementWidth, maxWidth: containerQuery.maxWidth, displayAsMobile },
        { setDisplayAsMobile }
      );
    }
  }, [containerQuery, elementWidth] );

  /* This handles inspecting the child and trying to determine
   * if there's a forwarded ref or not.
   */
  const hasRef = Content?.['$$typeof'] && String( Content['$$typeof'] ) === 'Symbol(react.forward_ref)';

  const containerQueryCallback = useCallback( utils.configureContainerQuery( { containerQuery }, { setContainerQuery } ), [
    containerQuery,
    setContainerQuery
  ] );

  /* EventProvider - Wires up the onEvent helper
   */
  const eventStore = useRef( [] );
  const { moduleName, subscribedEvents } = localProps;
  const onEvent = useCallback( composeOnEvent( { subscribedEvents, eventStore, moduleName }, { subscribe } ), [
    localProps.subscribedEvents
  ] );

  /* EventProvider - Wires up the publishEvent helper
   */
  const publishEventProxy = useCallback( utils.composePublishEvent( { moduleName }, { publishEvent } ), [
    moduleName,
    publishEvent
  ] );

  /* EventProvider - Handles unsubscribing from events on unmount
   */
  useEffect( () => {
    return utils.composeUnsubscribeLocalEvents( { eventStore }, { unsubscribe } );
  }, [] );

  const loadingReduced = utils.reduceLoadingStatus( {
    loadingStatus: [parentProps.loading, localProps.loading, loading]
  } );
  const [loader] = useLoader( { loading: loadingReduced, isPageLoader: true, color: 'orange-400' } );
  const isPageLoaderVisible = isPageLoader && loadingReduced;

  if( !Content ){
    return null;
  }

  if( isServer() ){
    global.layerHostRenderStack[global.layerHostRenderStack.length] = {
      moduleName: localProps.moduleName,
      id: localProps.id
    };
  }

  return (
    <>
      { anchorID && <div id={ anchorID }/> }
      <Content
        { ...( hasRef ? { ref: moduleRef } : {} ) }
        { ...localProps }
        onEvent={ onEvent }
        publishEvent={ publishEventProxy }
        hasEvent={ hasEvent }
        invokeAction={ localProps.invokeAction || setInvokeQuery }
        invokeMutation={ localProps.invokeMutation || setInvokeMutation }
        configureContainerQuery={ containerQueryCallback }
        containerLayerHostContentId={ moduleContentId || parentProps.containerLayerHostContentId }
        loading={ loadingReduced }
        error={ error }
        componentKey={ localProps.componentLastUpdated || localProps.componentKey }
        { ...( containerQuery?.maxWidth && { displayAsMobile } ) }
        setShowSpacer={ setShowSpacer }
      />
      { showSpacer &&
        <Spacer key={ `${localProps.componentLastUpdated}-spacer` }
          value={ spacerValue }
        /> }
      { isPageLoaderVisible && loader }
    </>
  );
};

/**
 * Composes the onBroadcast callback for this layer host
 * @param {object} data - arguments
 * @param {object} methods - methods
 * @returns {function} - onBroadcast callback
 */
export const composeOnBroadcastCallback = ( data, methods ) => ( module ) => {
  const { setLoading, setLocalProps, setMeta } = methods || {};
  const { id } = data || {};
  const { content = {}, meta = {} } = handleEmptyObjects( module );

  // We only want to update the local state if the id matches
  if( content.id !== id ){
    return;
  }

  setLoading( false );
  setLocalProps( content );

  if( meta ){
    setMeta( meta );
  }
};

/**
 *
 * @param {object} data - arguments
 * @param {array} data.loadingStatus - arguments
 */
export const reduceLoadingStatus = ( data ) => {
  const { loadingStatus = [] } = handleEmptyObjects( data );

  for ( const status of loadingStatus ){
    if( typeof status === 'boolean' ){
      return status;
    }
  }

  return null;
};

/**
 * Reconciles when to update props passed down to parent due to a full page request:
 * 1. React will render children first to determine it's dependencies
 *   so we need to determine if incoming parentProps are actually newer:
 *   1a. Before the new page data trickles down, each child re-renders once with it's previous props.
 *   2b. This is due to how React reconciles dependencies, for more information:
 *       https://imkev.dev/react-rendering-order
 *
 * 2. We make determining when to propagate props from the parent easy by:
 *    2a. On a page update we decorate each module with the pageLastUpdated timestamp
 *    2b. If `parentProps.componentLastUpdated` is older than the actual `pageLastUpdated`,
 *        we know the props are stale and we can choose not to update until the fresh object
 *        comes down from the parent
 *
 * @param {object} data - Data
 * @param {LayerHostProps} data.localProps - Local props
 * @param {LayerHostProps} data.parentProps - Parent props (or initial props), invoked by a parent component
 * @param {number} data.pageLastUpdated - Parent props (or initial props), invoked by a parent component
 * @param {object} methods - Methods
 * @param {function} methods.setLoading - Local props
 * @param {function} methods.setLocalProps - Parent props (or initial props), invoked by a parent component
 * @param {function} methods.setNextProps - Props used while loading an initAction
 * @param {function} methods.setMeta - Parent props (or initial props), invoked by a parent component
 */
export const propComparisonHandler = ( data, methods ) => {
  const { pendingPageUpdate, localProps = {}, parentProps = {}, pageLastUpdated, metaLastUpdated = {} } = data || {};
  const { setLoading, setLocalProps, setNextProps, setMeta, setPendingPageUpdate } = methods || {};
  const { ignoreUpdates, moduleName } = localProps || {};

  if(
    !localProps ||
    !parentProps ||
    !isFunction( setLoading ) ||
    !isFunction( setLocalProps ) ||
    !isFunction( setNextProps ) ||
    !isFunction( setMeta )
  ){
    return;
  }

  const localNewerThanPage =
    // If there was a local update, we will always want the local props
    localProps.componentLastUpdated > pageLastUpdated ||
    // While React reconciles during a re-render due to a page update, parent props
    // from a wrapping AsyncComponent -> Loadable -> LayerHost will be older
    parentProps.componentLastUpdated < pageLastUpdated ||
    // If we're processing meta information like a session update we don't want to update the UI
    metaLastUpdated.current > pageLastUpdated;

  const parentNewerThanLocal = parentProps.componentLastUpdated > localProps.componentLastUpdated;

  // We never want updates from the parent for the overlay after initial render
  const isStaleOverlay = moduleName === OVERLAY_CONTAINER_MODULE_NAME;

  const noLocalOrParentUpdate =
    localProps.componentLastUpdated === parentProps.componentLastUpdated &&
    localProps.componentLastUpdated === pageLastUpdated;

  const hasLocalUpdate = localNewerThanPage && !parentNewerThanLocal;

  /*
   * If local state is newer, do nothing. We also never want to accept parent props inside an overlay
   */
  if( ignoreUpdates || isStaleOverlay || noLocalOrParentUpdate || hasLocalUpdate ){
    return;
  }

  // We've updated the page, so we can reset the flag
  setPendingPageUpdate( null );

  // Check if the an incoming update has an initAction, if so we want to signal loading
  // and prepare to update the props once the initAction has been processed
  const hasInitAction = parentProps.meta?.initAction?.graphql;

  // If the incoming props to compare are at a page level, but there's also an initAction for showing
  // and overlay, we want to both set local props and show the overlay so we skip the next props action

  // We set "nextProps" so that our action processor can still access any updated props for the
  // graphql query, while maintating the current UI state
  if( hasInitAction && !isOverlay( parentProps.meta?.initAction?.navigationType ) ){
    setNextProps( parentProps );
    setLoading( true );
  }
  // Otherwise, we can update the props immediately
  else {
    setLocalProps( parentProps );
  }

  // If there is a module waiting on a page update, we want to wait for the page props to make their way down
  if( localProps.moduleName === pendingPageUpdate ){
    // 1. Set loading explicitly to false when the page update is for this module
    setTimeout( () => setLoading( false ), 0 );
  }
  else {
    // 2. Otherwise, set loading to whatever the parent props are
    setLoading( parentProps.loading );
  }

  if( parentProps.meta ){
    setMeta( parentProps.meta );
  }
};

/**
 * Publish event for child components
 *
 * @method composePublishEvent
 * @param {object} data arguments
 * @param {object} data.moduleName child moduleName
 * @param {object} methods methods
 * @param {function} methods publishEvent this is from the Event Provider
 */
export const composePublishEvent = ( data, methods ) => ( eventData ) => {
  const { moduleName } = data || {};
  const { publishEvent } = methods || {};

  if( !moduleName || !publishEvent ){
    return;
  }

  publishEvent( { ...eventData, moduleName } );
};

/**
 * This is for unsubscribing to local events
 *
 * @method composeUnsubscribeLocalEvents
 * @param {object} data arguments
 * @param {object} data.eventStore store for all the events
 * @param {object} methods methods
 * @param {function} methods unsubscribe
 */
export const composeUnsubscribeLocalEvents = ( data, methods ) => () => {
  const { eventStore } = data || {};
  const { unsubscribe } = methods || {};

  if( !unsubscribe ){
    return;
  }

  unsubscribe( { events: eventStore.current } );
};

/**
 * Configures the container query
 *
 * @method
 * @param {object} methods
 * @param {object} data
 */
export const configureContainerQuery = ( data, methods ) => {
  return ( config ) => {
    if( config?.maxWidth !== data.containerQuery?.maxWidth ){
      methods.setContainerQuery( config );
    }
  };
};

/**
 * Container Query - Updates this layer's current width
 *
 * @method
 * @param {object} data
 * @param {object} methods
 */
export const handleElementWidth = ( data, methods ) => {
  const { moduleRef } = data || {};
  const { setElementWidth } = methods || {};

  if( !setElementWidth || !isSafeNumber( moduleRef?.offsetWidth ) ){
    return;
  }

  setElementWidth( moduleRef.offsetWidth );
};

/**
 * Conatiner Query - Sets dispayAsMobile to true or false based on container width
 *
 * @method
 * @param {object} methods
 * @param {object} data
 */
export const handleSetDisplayAsMobile = ( data, methods ) => {
  const { elementWidth, maxWidth, displayAsMobile } = data || {};
  const { setDisplayAsMobile } = methods || {};

  if( elementWidth < maxWidth && !displayAsMobile ){
    setDisplayAsMobile( true );
  }
  if( elementWidth > maxWidth && displayAsMobile ){
    setDisplayAsMobile( false );
  }
};

/**
 * Handles executing the query or mutation
 *
 * @param {object} data args
 * @param {object} methods methods
 */
export const handleAction = ( data, methods ) => {
  const { action = {}, isMutation, isMutationInFlight, actionAfterRef } = data || {};
  const { actionHandler, setAction, setIsMutationInFlight, setIsPageLoader } = methods || {};

  if( !setAction || !action.graphql || !action.variables || Object.keys( action.variables )?.length === 0 ){
    return;
  }

  const isPageLoader = action?.isPageLoader === true;

  setIsPageLoader( isPageLoader );

  if( action.after ){
    actionAfterRef.current = action.after;
  }

  if( action.dataCaptureData ){
    const resolveDataCapture = datacapture.resolveFormValuesDataLayer( { action } );
    datacapture.processEvents( { dataCapture: { ...resolveDataCapture } }, resolveDataCapture?.clientEvent?.toLowerCase() );
  }

  // this handles preventing firing other mutations if we are meant to be blocking the page
  // TODO: follow up on other use cases, we might want to block mutations in other scenarios
  if( isPageLoader && isMutation && isMutationInFlight ){
    return;
  }

  if( isMutation ){
    setIsMutationInFlight( true );
  }

  /*
   * Will be either Apollo useMutation or useQuery
   */
  actionHandler( {
    ...action
  } );

  // Teardown the action, so that we don't execute it again
  setAction( null );
};

/**
 * Determine if spacer have to be rendered from layerHost
 * Return true if the spacer should not be rendered from LayerHost
 * else return false
 *
 * @param {object} moduleName String
 */
export const shouldDeferSpacer = ( moduleName ) => {
  const moduleList = constants.SPACER_IGNORED_MODULES;
  if( moduleList.includes( moduleName ) ){
    return true;
  }
  return false;
};
/**
 * Process analytics data
 *
 * @param {object} data args
 */
export const processMetaAnalytics = ( data ) => {
  const { meta = {} } = data || {};

  if( !meta.metaDataLayerList ){
    return;
  }

  const sessionPipeline = meta.metaDataLayerList.reduce( ( result, element ) => {
    const accumulator = result;
    if( element && TEALIUM_KEY in element ){
      const tealium = element[TEALIUM_KEY];
      for ( const key of Object.keys( tealium ) ){
        accumulator[key] = tealium[key];
      }
    }
    return result;
  }, {} );

  setStorage( { secure: false, key: STORAGE_KEY.layerhostDatacapturePostProcess, value: sessionPipeline } );
};

/**
 * @const {string} TEALIUM_KEY - Tealium data layer key
 */
export const TEALIUM_KEY = 'Tealium';

/*
 * DXL response handler
 *
 * @param {object} data response information
 * @param {object} methods methods
 */
export const handleDXLResponse = ( data, methods ) => {
  const { actionAfterRef, props = {}, isMutation, loading, data: responseData, error, metaLastUpdated = {} } = handleEmptyObjects( data );
  const {
    broadcast,
    DONOTUSE_broadcastInitAction,
    setLoading,
    setData,
    setMeta,
    setError,
    setModuleContentId,
    setPageData,
    setNextProps,
    setPendingPageUpdate,
    setIsMutationInFlight,
    setStore
  } = methods || {};

  if( !loading && !responseData && !error ){
    return;
  }

  // Set error state
  setError( error );

  // Always set loading when true
  if( loading ){
    setLoading( loading );
    return;
  }

  if( !responseData ){
    return;
  }

  // We want to extract the module response from the DXL response object
  let content = null;
  let meta = null;

  for ( const value of Object.values( responseData ) ){
    if( value?.content ){
      content = value.content;
    }

    if( value?.meta ){
      meta = value.meta;
      utils.processMetaAnalytics( { meta } );
    }
  }

  const lastUpdated = Date.now();

  const isIncremental = content?.moduleName === IncrementalModule.displayName;
  const isPageUpdate = content?.moduleName === PAGE_MODULE_NAME;
  const isOverlayContainer = props.moduleName === OVERLAY_CONTAINER_MODULE_NAME;
  const isStorePersist = content?.moduleName === 'StorePersist';

  // Stop-gap: For real time prescreen we need to broadcast the initAction
  // Remove when: DXL creates a VirtualModule for side effects
  if( props.moduleName === 'StateWrapper' && meta?.initAction?.graphql ){
    DONOTUSE_broadcastInitAction( { initAction: meta.initAction } );
  }

  // Only update meta if available and not a page/incremental update. Meta will be updated at the page level
  else if( meta && !isPageUpdate && !isIncremental ){
    setMeta( meta );
    metaLastUpdated.current = lastUpdated;
  }

  // Only update loading if it's not a page update, otherwise we want to wait for page props
  // to make their way down to this layer and set loading at that point in the prop comparison handler
  if( isPageUpdate && !isOverlayContainer ){
    setPendingPageUpdate( props.moduleName );
  }
  else {
    setLoading( loading );
  }

  // Reset "nextProps", which are used to signal an initAction for processing while leaving the
  // existing props in place per layer. This helps w/ flashing content or empty UI states while
  // things are loading
  setNextProps( null );

  if( isMutation ){
    setIsMutationInFlight( false );
  }

  if( !content ){
    // handles invoking action.after callback if passed from action if content is null
    if( actionAfterRef?.current ){
      actionAfterRef.current( { content, meta } );
      actionAfterRef.current = null;
    }

    return;
  }

  const isOverlayUpdate = props.moduleName === 'OverLayContainer' && content.moduleName === 'OverLayContainer';
  const isSdkUpdate = props.moduleName === 'Sdk';

  // If the module is a page, dispatch a page update - needed for protected page flow + page timestamp update
  let hasProcessedResponse = false;
  if( isPageUpdate ){
    setPageData( { loading: false, error, data: responseData } );
    hasProcessedResponse = true;
  }
  // Incremental response is a module array so loop and broad cast
  else if( isIncremental && hasItems( content.modules ) ){
    devLogger( '[Layerhost Broadcast] Mapping over Incremental Modules', 1 );
    for ( const module of content.modules ){
      const timestamped = utils.timestampContent( { lastUpdated, content: module } );
      broadcast( { id: timestamped.id, content: timestamped, meta } );
    }
    hasProcessedResponse = true;
  }
  // Broadcast if id mismatch. SDK is a specia execpetion that doesn't fit in this pattern
  else if( props.id !== content.id && !isSdkUpdate && !isOverlayUpdate ){
    devLogger( '[Layerhost Broadcast] - ID mismatch, broadcasting...', 1 );
    const timestamped = utils.timestampContent( { lastUpdated, content } );
    broadcast( { id: timestamped.id, content: timestamped, meta } );
    hasProcessedResponse = true;
  }
  // Required for PDP overlay variant picker logic until we can correct this with a DXL change
  // this is a temporary fix to handle post add bag overlay container issues. need to align with DXL to get the actual fix.
  else if( props.id !== content.id && props.moduleName === 'OverLayContainer' && Array.isArray( props.modules ) ){
    for ( const module of props.modules ){
      if( module.id === content.id ){
        devLogger( '[Layerhost Broadcast] - ID mismatch, broadcasting variant changes', 1 );
        const timestamped = utils.timestampContent( { lastUpdated, content } );
        broadcast( { id: timestamped.id, content: timestamped, meta } );
        hasProcessedResponse = true;
      }
    }
  }
  if( isStorePersist ){
    setStore( content );
    hasProcessedResponse = true;
  }
  if( ! hasProcessedResponse ){
    const timestamped = utils.timestampContent( { lastUpdated, content } );
    setData( timestamped );
    setModuleContentId( timestamped.id );
  }

  // handles invoking action.after callback if passed from action
  if( actionAfterRef?.current ){
    actionAfterRef.current( { content, meta } );
    actionAfterRef.current = null;
  }
};

export const timestampContent = ( data ) => {
  const { content, lastUpdated } = handleEmptyObjects( data );

  // Deep copy, needed to get around read only properties passed back from Apollo
  const mutableContent = JSON.parse( JSON.stringify( content ) );

  const newProps = { ...mutableContent, componentLastUpdated: lastUpdated };

  // Invalidate children timestamps so that they accept new props from this parent
  const childrenLastUpdated = lastUpdated - 1;

  newProps.modules = decorateModules( { modules: newProps.modules, componentLastUpdated: childrenLastUpdated } );

  return newProps;
};

/**
 * @typedef LayerHostProps
 * @type {object}
 *
 * @property {string} id Component id
 * @property {string} moduleName Component name
 * @property {?object} meta Component meta
 * @property {React.FC} Content Component id
 * @property {number} componentLastUpdated module's last update
 * @property {?function} invokeAction Component's container invokeAction, if exists
 * @property {?function} invokeMutation Component's container invokeMutation, if exists
 * @property {?boolean} loading Component's container loading state, if exists
 * @property {?string} containerLayerHostContentId Component's container id, if exists
 */
export const propTypes = {
  id: PropTypes.string,
  moduleName: PropTypes.string,
  meta: PropTypes.object,
  Content: PropTypes.oneOfType( [PropTypes.func, PropTypes.object, PropTypes.element, PropTypes.symbol] ),
  componentLastUpdated: PropTypes.number,
  invokeAction: PropTypes.func,
  invokeMutation: PropTypes.func,
  loading: PropTypes.bool,
  containerLayerHostContentId: PropTypes.string
};

/**
 * Default values for passed properties
 * @type {object}
 */
export const defaultProps = {
  Content: null,
  ...constants.INTERSECTION_OBSERVER_OPTIONS,
  enableSpacer: true
};

LayerHost.propTypes = propTypes;
LayerHost.defaultProps = defaultProps;

export default LayerHost;
