/**
 * Component  for MediumDealCard component
 *
 * @module views/components/MediumDealCard
 * @memberof -Common
 */
import './MediumDealCard.scss';

import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Image from '@ulta/core/components/Image/Image';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';

import { composeClickHandler, isActionStyle } from '@ulta/modules/ActionGroup/ActionGroup';
import Tag from '@ulta/modules/Tag/Tag';

import carouselUtils from '@ulta/utils/carouselUtils/carouselUtils';

import * as utils from './MediumDealCard';

/**
 * Represents a MediumDealCard component
 *
 * @method
 * @param {MediumDealCardProps} props - React properties passed from composition
 * @returns MediumDealCard
 */

export const MediumDealCard = function( props ){
  const { action1, action2, action1Style, activateAction, eyebrow, offerId, headline, image, isOfferActivated, tag, urgencyTag, subHeadline, invokeMutation } = props;

  const handleActivateOffer = useCallback( utils.composeActivateOffer( { activateAction, offerId }, { invokeMutation } ), [] );
  const shouldPreferAction1 = isOfferActivated || !activateAction;
  const clickHandlerAction1 = action1 !== null ? composeClickHandler( { action: action1 } ) : undefined;

  const tagMessage = carouselUtils.computeProductTag( tag, urgencyTag?.timerMeta?.endsInLabel );
  const shouldShowAction2 = activateAction ? isOfferActivated && action2?.label : action2?.label;

  return (
    <div className='MediumDealCard'
      role='group'
      aria-label='Offer'
    >
      <div className='MediumDealCard__image'>
        { image?.imageUrl && <Image
          alt={ image?.altText }
          src={ image?.imageUrl }
          metaData={ {
            imageTemplate: 'ProductCardNeutralBG'
          } }
        /> }
      </div>
      <div className='MediumDealCard__details'>
        { tagMessage && (
          <div className='MediumDealCard__tag'>
            <Tag
              message={ tagMessage }
              backgroundColor='content-brand-02-strong'
              textColor='content-knockout'
            />
          </div>
        ) }
        { eyebrow &&
          <div className='MediumDealCard__eyebrow'>
            <Text textStyle='eyebrow'>{ eyebrow }</Text>
          </div>
        }
        { headline &&
          <div className='MediumDealCard__headline'>
            <Text textStyle='title-6'>{ headline }</Text>
          </div>
        }
        { subHeadline &&
          <div className='MediumDealCard__subHeadline'>
            <Text textStyle='body-2'>{ subHeadline }</Text>
          </div>
        }
      </div>
      <div className='MediumDealCard__actions'
        role='alert'
      >
        { ( action1?.label || activateAction?.label ) &&
          <Button
            size='tiny'
            variant={ isOfferActivated ? 'secondary' : 'primary' } // Fallback
            disabled={ isOfferActivated } // Fallback
            action={ shouldPreferAction1 ? action1 : activateAction }
            iconName={ isOfferActivated && 'check' }
            label={ ( isOfferActivated ? action1?.label : activateAction?.label ) ?? action1?.label ?? activateAction?.label }
            { ...isActionStyle( action1Style, isOfferActivated ) }
            onClick={ shouldPreferAction1 ? clickHandlerAction1 : handleActivateOffer }
          />
        }
        { shouldShowAction2 &&
          <div className='MediumDealCard__action2'>
            <Link_Huge
              target='_self'
              withHover
              ariaLabel={ action2?.label }
              action={ action2 }
            >
              <Text
                htmlTag='span'
                textStyle='body-2'
              >
                { action2?.label }
              </Text>
            </Link_Huge>
          </div>
        }
      </div>
    </div>
  );
};

/**
 * A click handler function for mutation call
 * @method
 * @param {Object} methods - Passing methods as an argument for the mutation call
 * @param {Object} data - Passing data as an argument
 */
export const composeActivateOffer = ( data, methods ) => () => {

  const { invokeMutation } = methods || {};
  const { activateAction, offerId } = data || {};

  if( !activateAction.graphql || !invokeMutation || !offerId ){
    return;
  }

  invokeMutation( {
    ...activateAction,
    variables: {
      url: { path: !isServer() && global.location.pathname },
      moduleParams: {
        offerId
      }
    }
  } );
};

/**
 * Property type definitions
 * @typedef MediumDealCardProps
 * @type {object}
 * @property {object} action1 - The action1 for the card, always a button. This prop is generally for the "Activated" state.
 * @property {object} action2 - The action2 for the card, always a link.
 * @property {object} activateAction - The action for a card that has not yet been "Activated".
 * @property {string} action1Style - This is the action1Style (primary, secondary, etc) for the MediumDealCard
 * @property {string} action2Style - This is the action2Style (primary, secondary, etc) for the MediumDealCard
 * @property {string} eyebrow - This is the eyebrow for the MediumDealCard
 * @property {string} headline - This is the headline for the MediumDealCard
 * @property {object} image - This is the image for the MediumDealCard
 * @property {bool} isOfferActivated - True if offer is activated; otherwise, false
 * @property {string} subHeadline - This is the subHeadline for the MediumDealCard
 * @property {string} tag - This is the tag for the MediumDealCard
 * @property {string} urgencyTag - This is the urgencyTag for the MediumDealCard
*/
export const propTypes = {
  action1: PropTypes.shape( {
    url: PropTypes.string,
    label: PropTypes.string
  } ),
  action2: PropTypes.shape( {
    url: PropTypes.string,
    label: PropTypes.string
  } ),
  activateAction: PropTypes.shape( {
    url: PropTypes.string,
    label: PropTypes.string
  } ),
  action1Style: PropTypes.string,
  action2Style: PropTypes.string,
  eyebrow: PropTypes.string,
  headline: PropTypes.string,
  image: PropTypes.object,
  isOfferActivated: PropTypes.bool,
  subHeadline: PropTypes.string,
  tag: PropTypes.string,
  urgencyTag: PropTypes.shape( {
    type: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    backgroundColor: PropTypes.string,
    timerMeta: PropTypes.object
  } )
};

MediumDealCard.propTypes = propTypes;

export default MediumDealCard;