/**
 * The Icon component returns an SVG Icon image
 * @module views/Atoms/Icon
 * @memberof -Common
 */
import React from 'react';

import PropTypes from 'prop-types';
import PaletteIcon from 'web-palette/dist/components/Icon/Icon';

/**
 * Component to display icons
 *
 * @method
 * @param {object} size - Sets the size of the icon
 * @param {object} name - Sets the icon's figure
 * @param {IconProps} props - Additional React properties passed from composition
 * @returns Icon
 */
const Icon = ( props ) => {
  const {
    size,
    name,
    // ADA props
    title,
    description,
    role,
    ...restProps
  } = props;

  if( !name ){
    return null;
  }

  const PalIcon = PaletteIcon.default || PaletteIcon;

  return (
    <PalIcon
      name={ name }
      size={ getPaletteIconSize( size ) }
      title={ title }
      description={ description }
      role={ role }
      { ...restProps }
    />
  );
};

const getPaletteIconSize = ( size ) => {
  // legacy icon sizes are 's', 'm', 'l', 'customSocialMedium', 'customSocialLarge', 'customMedium' and 'customLarge'
  // Palette icon sizes are 'default', 'lg', 'xl', 'xxl', 'cc' and 'custom'
  switch ( size ){
    case 's':
    case 'default':
      return 'default'; // Default icon size is 12x12
    case 'm':
    case 'lg':
      return 'lg'; // 16x16
    case 'l':
    case 'customSocialMedium':
    case 'customSocialLarge':
    case 'xl':
      return 'xl'; // 24x24 - Palette has only one size for social and conscious icons
    case 'xxl':
      return 'xxl'; // 40x40 - Largest icon size in Palette
    case 'cc': // new icon size for payment icons
      return 'cc'; // 40x24
    case 'customMedium':
    case 'customLarge':
    case 'custom':
      return 'custom'; // 100%x100% - size is set as specified on the svg
    default:
      return 'default'; // return default size
  }
};

/**
 * Property type definitions
 * @typedef IconProps
 * @type {object}
 * @property {string} name - Name of the icon
 * @property {string} title - SVG title which serves as alt text for the SVG.
 * @property {string} size - The size of the icon
 * @property {string} description - SVG description for ADA
 * @property {string} role - Role of the svg
 */

export const propTypes = {
  /**
   * Name of the icon (from the first column in the Storybook table)
   */
  name: PropTypes.string,
  /**
   * ID used so the svg can read the title of the SVG icon to the user when accessibility is needed
   */
  titleId: PropTypes.string,
  /**
   * ID used for desc tag on SVG for accessibility when needed
   */
  descId: PropTypes.string,
  /**
   * SVG title which serves as alt text for the SVG.
   */
  title: PropTypes.string,
  /**
   * SVG description for ADA
   */
  description: PropTypes.string,
  /**
   * The size of the icon
   * - default - 12X12  size of the UI icons
   * - lg - 16X16 size of the UI icons
   * - xl - 24X24 size used for social and conscious icons
   * - xxl - 40X40 largest icon size for UI icons
   * - cc - 40X24 size to be used for credit cards and payment icons
   * - custom - 100% X 100% size for icons with custom height and width provided through svg
   */
  size: PropTypes.string,
  /**
   * role of the svg
   */
  role: PropTypes.string
};

/**
 * Function to format legacy icon size props to palette icon size props. We prefer palette props when they are present.
 * @method
 * @param {Object} event
 * @returns { String } // string for icon size
 */


Icon.propTypes = propTypes;

export default Icon;
