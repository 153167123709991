/**
 * Initial click on the AddToBagButton should trigger the addToBagAction.graphql, subsequent clicks on &#x27;+&#x27; or &#x27;-&#x27; should trigger updateBagAction.graphql until the quantity reaches 1 and the user clicks on &#x27;-&#x27;, then it should trigger removeFromBagAction.graphql. When the quantity reaches maxQuantity, the &#x27;+&#x27; should be disabled
 *
 * @module views/components/AddToBagButton
 * @memberof -Common
 */
import './AddToBagButton.scss';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Icon from '@ulta/core/components/Icon/Icon';
import Text from '@ulta/core/components/Text/Text';

import { broadcastMessage } from '@ulta/utils/accessibility/accessibility';

import * as utils from './AddToBagButton';

/**
 * Represents a AddToBagButton component
 *
 * @method
 * @param {AddToBagButtonProps} props - React properties passed from composition
 * @returns AddToBagButton
 */
export const AddToBagButton = function( props ){
  const [loader, setLoader] = useState( false );

  const [showAnimation, setShowAnimation] = useState( false );
  const animationFlag = useRef( false );
  const animationTimerFunc = useRef( null );

  const lastAction = useRef();
  const mainButtonRef = useRef();
  const increaseButtonRef = useRef();
  const descreaseButtonRef = useRef();

  const {
    addToBagAction,
    commerceId,
    default: defaultState,
    fulfillmentType,
    inBagLabel,
    incrementBagAccessibilityLabel,
    invokeMutation,
    isSecondary,
    maxQuantity,
    productId,
    quantity,
    removeFromBagAction,
    skuId,
    successLabel
  } = props;

  /**
   * Handle add to bag response
   * -> We want to always run this any time quantity changes
   */
  const addToBagHandler = useCallback(
    utils.composeAddToBagResponse(
      { lastAction, animationFlag, quantity, descreaseButtonRef, increaseButtonRef, maxQuantity },
      { setLoader, setShowAnimation }
    ),
    [quantity]
  );

  useEffect( addToBagHandler, [addToBagHandler] );

  /**
   * Handle remove from bag resposne
   * -> We want to always run this any time quantity changes
   */
  const removeFromBagHandler = useCallback(
    utils.composeRemoveFromBagResponse(
      { lastAction, animationFlag, animationTimerFunc, mainButtonRef, quantity },
      { setLoader, setShowAnimation }
    ),
    [quantity]
  );

  useEffect( removeFromBagHandler, [removeFromBagHandler] );

  /**
   * ADA -> Broadcasts cart modifications for screen readers
   */
  const a11yHandler = useCallback( utils.composeA11yResponse( { quantity, inBagLabel, zeroLabel: '0 in bag' } ), [
    inBagLabel,
    quantity
  ] );

  useEffect( a11yHandler, [a11yHandler] );

  /**
   * Compose increase/decrease callbacks
   */
  const callbacksData = { props, lastAction, animationFlag, animationTimerFunc };
  const callbacksMethods = { invokeMutation, setLoader, setShowAnimation };
  const { increaseQty, decreaseQty, initialIncrease } = useMemo(
    () => utils.composeCallbacks( callbacksData, callbacksMethods ),
    [quantity, skuId, productId, commerceId, fulfillmentType]
  );

  const showMainButton = !loader && !quantity && !showAnimation && !isSecondary;
  const showQtyButtons = !!quantity && !loader;
  const showAddedMessage = !loader && !!quantity && !showAnimation && !isSecondary;
  const showSecondaryButton = isSecondary && !loader && !quantity && !showAnimation;

  const atbAction = {
    ...addToBagAction,
    variables :{
      moduleParams: {
        skuId,
        productId,
        fulfillmentType
      }
    }
  };

  return (
    <div className='AddToBagButton'>
      { showQtyButtons && (
        <Button
          iconSize={ 'm' }
          primary
          iconImage='Minus'
          className='AddToBagButton__Minus'
          withHover
          ariaLabel={ removeFromBagAction.label }
          onClick={ () => {
            decreaseQty();
          } }
          action={ removeFromBagAction }
          ref={ descreaseButtonRef }
        />
      ) }

      { showAnimation && (
        <div className='AddToBagButton__AddedProgress'>
          <div className='AddToBagButton__SvgContainer'>
            <Text htmlTag='span'>
              <Icon className={ classNames( 'AddToBagButton__Check' ) }
                aria-hidden={ true }
                size={ 's' }
                name={ 'Check' }
                burst
              />
            </Text>
          </div>
          <Text htmlTag='span'
            textStyle='body-3'
          >
            { successLabel }
          </Text>
        </div>
      ) }

      { showMainButton && (
        <Button
          { ...{ onClick: initialIncrease, className: 'AddToBagButton__AddToBag' } }
          label={ inBagLabel ? inBagLabel : addToBagAction?.label }
          action={ addToBagAction }
          ref={ mainButtonRef }
          disabled={ defaultState?.disabled }
        />
      ) }

      { showSecondaryButton && (
        <Button
          { ...{ onClick: initialIncrease, className: 'AddToBagButton__AddToBagOverlay' } }
          label={ inBagLabel ? inBagLabel : addToBagAction?.label }
          action={ atbAction }
          ref={ mainButtonRef }
          secondary
          disabled={ defaultState?.disabled }
          size='compact'
        />

      ) }

      { showAddedMessage && (
        <div className='AddToBagButton__Added'>
          <Text htmlTag='span'
            textStyle='body-2'
            fontWeight='bold'
          >
            { inBagLabel || addToBagAction?.label }
          </Text>
        </div>
      ) }

      { showQtyButtons && (
        <Button
          primary
          iconSize={ 'm' }
          className='AddToBagButton__Add'
          { ...( quantity >= defaultState?.maxQuantity && { disabled: true, secondary: true } ) }
          iconImage='Add'
          withHover
          ariaLabel={ incrementBagAccessibilityLabel }
          onClick={ () => {
            increaseQty();
          } }
          action={ addToBagAction }
          ref={ increaseButtonRef }
        />
      ) }
    </div>
  );
};

/**
 * Compose callback to handle add to bag button click
 * @param {object} data - data object passed in as an argument
 * @param {object} data.lastAction - React ref to store last user action
 * @param {object} data.props - props
 * @param {object} data.animationFlag - React ref to get response
 * @param {object} data.animationTimerFunc - React ref to set timeout value/id
 * @param {object} methods - object of methods passed in as an argument
 * @param {function} methods.invokeMutation - invoke mutation
 * @param {function} methods.setLoader - set state when add/remove is in progress
 * @param {function} methods.setShowAnimation- set state to show/hide animation
 * @returns {{ decreaseQty, increaseQty, initialIncrease }} Handles quantity while click on add button
 */
export const composeCallbacks = ( data, methods ) => {
  const { props, lastAction, animationFlag, animationTimerFunc } = data || {};
  const { invokeMutation, setLoader, setShowAnimation } = methods || {};

  const { quantity, skuId, productId, removeFromBagAction, addToBagAction, fulfillmentType } = props || {};

  /**
   * Remove from bag handler
   */
  const decreaseQty = () => {
    lastAction.current = BAG_ACTIONS.Remove;
    invokeMutation( {
      graphql: removeFromBagAction.graphql,
      customHeaders: removeFromBagAction.customHeaders,
      params:removeFromBagAction.params,
      variables: {
        contentId: props.id,
        moduleParams: { quantity: quantity - 1 }
      }
    } );
  };

  /**
   * Add to bag handler
   */
  const increaseQty = () => {
    lastAction.current = BAG_ACTIONS.Add;
    let newQty = quantity || 0;
    invokeMutation( {
      graphql: addToBagAction.graphql,
      customHeaders: addToBagAction.customHeaders,
      params: addToBagAction.params,
      variables: {
        contentId: props.id,
        moduleParams: { quantity: newQty + 1, productId, skuId, fulfillmentType }
      }

    } );
  };

  /**
   * Hanadles initial add to bag
   */
  const initialIncrease = () => {
    setLoader( true );
    increaseQty();
    animationFlag.current = false;
    setShowAnimation( true );
    animationTimerFunc.current = setTimeout( () => {
      if( animationFlag.current ){
        animationFlag.current = false;
        setShowAnimation( false );
      }
      else {
        animationFlag.current = true;
      }
      clearTimeout( animationTimerFunc.current );
      animationTimerFunc.current = null;
    }, ADD_TO_BAG_ANIMATION_DURATION );
  };

  return { decreaseQty, increaseQty, initialIncrease };
};

/**
 * @const {string} ADD_TO_BAG_ANIMATION_DURATION - Duration of add to bag animation
 */
export const ADD_TO_BAG_ANIMATION_DURATION = 1500;

/**
 * @const {object} BAG_ACTIONS - Tracks which user action was last performed
 */
export const BAG_ACTIONS = {
  Add: 'Add',
  Remove: 'Remove',
  Init: 'Init'
};

/**
 * Compose add to bag response
 * @param {object} data - data object passed in as an argument
 * @param {object} data.lastAction - React ref to store last user action
 * @param {object} data.animationFlag - React ref to get response
 * @param {object} methods - object of methods passed in as an argument
 * @param {function} methods.setLoader - set state when add/remove is in progress
 * @param {function} methods.setShowAnimation- set state to show/hide animation
 */
export const composeAddToBagResponse = ( data, methods ) => () => {
  const { lastAction, animationFlag, quantity } = data || {};
  const { setLoader, setShowAnimation } = methods || {};

  if( lastAction?.current !== BAG_ACTIONS.Add ){
    return;
  }

  lastAction.current = BAG_ACTIONS.Init;

  setLoader( false );

  if( !animationFlag.current ){
    animationFlag.current = true;
  }
  else {
    animationFlag.current = false;
    setShowAnimation( false );
  }

  if( quantity !== 1 ){
    return;
  }

  setTimeout( () => {
    const increaseButton = document.querySelector( '.AddToBagButton__Add' );
    if( increaseButton?.disabled ){
      document.querySelector( '.AddToBagButton__Minus' )?.focus();
    }
    else {
      increaseButton?.focus();
    }
    // TODO: When <Button> supports refs use the code below:
    // const buttonToFocus = increaseButtonRef.current?.disabled ? descreaseButtonRef.current : increaseButtonRef.current
    // buttonToFocus?.focus()
  }, 0 );
};

/**
 * Compose remove to bag response
 * @param {object} data - data object passed in as an argument
 * @param {object} data.lastAction - React ref to store last user action
 * @param {object} data.animationFlag - React ref to get response
 * @param {object} data.animationTimerFunc - React ref to set timeout value/id
 * @param {object} methods - object of methods passed in as an argument
 * @param {function} methods.setLoader - set state when add/remove is in progress
 * @param {function} methods.setShowAnimation- set state to show/hide animation
 */
export const composeRemoveFromBagResponse = ( data, methods ) => () => {
  const { lastAction, animationFlag, animationTimerFunc, quantity } = data || {};
  const { setLoader, setShowAnimation } = methods || {};

  if( lastAction?.current !== BAG_ACTIONS.Remove ){
    return;
  }

  lastAction.current = BAG_ACTIONS.Init;
  setLoader( false );
  setShowAnimation( false );
  animationFlag.current = false;

  if( animationTimerFunc.current ){
    clearTimeout( animationTimerFunc.current );
    animationTimerFunc.current = null;
  }

  if( quantity > 0 ){
    return;
  }

  setTimeout( () => {
    document.querySelector( '.AddToBagButton__AddToBag' )?.focus();
    // TODO: When <Button> supports refs use the code below:
    // mainButtonRef.current?.focus()
  }, 0 );
};

/**
 * Compose A11y response
 * @param {object} data - data object passed in as an argument
 * @param {number} data.quantity - bag count
 * @param {string} data.inBagLabel - Add button have qunatity label
 * @param {string} data.zeroLabel - Add button label
 */
export const composeA11yResponse = ( data ) => () => {
  const { quantity, inBagLabel, zeroLabel } = data || {};

  if( quantity ){
    broadcastMessage( { message: inBagLabel, broadcastId: ADD_TO_BAG_ADA_EVENT, delay: ADD_TO_BAG_ADA_INTERVAL } );
  }
  else {
    broadcastMessage( { message: zeroLabel, broadcastId: ADD_TO_BAG_ADA_EVENT, delay: ADD_TO_BAG_ADA_INTERVAL } );
  }
};

/**
 * @const {string} ADD_TO_BAG_ADA_EVENT - ADA broadcast event id
 */
export const ADD_TO_BAG_ADA_EVENT = 'addToBagBroadCast';

/**
 * @const {number} ADD_TO_BAG_ADA_INTERVAL - ADA broadcast cleanup timeout
 */
export const ADD_TO_BAG_ADA_INTERVAL = 30000;
/**
 * Property type definitions
 *
 * @typedef AddToBagButtonProps
 * @type {object}
 * @property {object} addToBagAction - Sets label and graphql
 * @property {object} updateBagAction - Sets label and graphql
 * @property {object} removeFromBagAction - Sets label and graphql
 * @property {string} successLabel - Sets success Label
 * @property {string} addToBagErrorLabel - Sets error Label
 * @property {string} inBagLabel - Sets in bag Label
 * @property {string} incrementBagAccessibilityLabel - Sets in bag Label
 * @property {number} maxQuantity - Sets max quantity
 * @property {number} quantity - quantity
 * @property {boolean} isSecondary - Sets type of the button
 * @property {string} fulfillmentType - fulfillmentType
 */
export const propTypes = {
  addToBagAction: PropTypes.shape( {
    label: PropTypes.string,
    graphql: PropTypes.string,
    params: PropTypes.array,
    customHeaders: PropTypes.array
  } ),
  updateBagAction: PropTypes.shape( {
    label: PropTypes.string,
    graphql: PropTypes.string
  } ),
  removeFromBagAction: PropTypes.shape( {
    label: PropTypes.string,
    graphql: PropTypes.string,
    params: PropTypes.array,
    customHeaders: PropTypes.array
  } ),
  successLabel: PropTypes.string,
  addToBagErrorLabel: PropTypes.string,
  inBagLabel: PropTypes.string,
  incrementBagAccessibilityLabel: PropTypes.string,
  maxQuantity: PropTypes.number,
  quantity: PropTypes.number,
  isSecondary: PropTypes.bool,
  fulfillmentType: PropTypes.string
};

AddToBagButton.propTypes = propTypes;

export default AddToBagButton;
