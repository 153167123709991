/**
 * The NavigationLink component, along with its href attribute, creates a hyperlink to other web pages. This component also having optional properties for icon and description.
 *
 * @module views/components/NavigationLink
 * @memberof -Common
 */
import './NavigationLink.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Icon from '@ulta/core/components/Icon/Icon';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Modules from '@ulta/core/components/Modules/Modules';
import Text from '@ulta/core/components/Text/Text';

import { isButton, isOnlyIcon } from '@ulta/utils/navUtils/navUtils';

/**
 * Represents a NavigationLink component
 *
 * @method
 * @param {NavigationLinkProps} props - React properties passed from composition
 * @returns NavigationLink
 */
export const NavigationLink = React.forwardRef( ( props, _ ) => {

  const navDescription = props.description ? props.description : props.action?.description ;

  return (
    <div className='NavigationLink'
      { ...( props.isLeftNavigation && { role:'region' } ) }
      aria-labelledby={ props.ariaLabelBy }
      { ...( props.parentID && { 'id': props.parentID } ) }
    >
      { props.action?.label && props.action?.url && !props.modules &&
        <Link_Huge
          inline={ props.isInline }
          secondary={ !props.isInline }
          withHoverUnderline
          action={ props.action }
        >
          <Text
            textStyle={ props.textStyle }
            htmlTag={ props.htmlTag }
            color={ props.color }
          >
            { props.action.label }
          </Text>
          { props.icon &&
            <Icon
              className='NavigationLink__icon'
              size={ props.iconSize? props.iconSize : 'cc' }
              name={ props.icon }
              title={ props.icon }
              { ...( props.description && { description:props.description } ) }
            />
          }
          {
            navDescription &&
            <Text
              htmlTag='p'
              textStyle='body-3'
              color='neutral-600'
            >
              { navDescription }
            </Text>
          }
        </Link_Huge>
      }

      { props.action?.clientActionType &&
        <div className='NavigationLink__clientAction'>
          <Button
            action={ props.action }
            { ...( !props.isPrimary ) && { secondary: true } }
            variant= 'linkSecondary'
            label={ <>
              <Text
                textStyle={ props.textStyle }
                htmlTag={ props.htmlTag }
                color={ props.color }
              >
                { props.action.label }
              </Text>
              { props.icon &&
                <Icon
                  className='NavigationLink__icon'
                  size={ props.iconSize ? props.iconSize : 'cc' }
                  name={ props.icon }
                  title={ props.icon }
                  { ...( props.description && { description:props.description } ) }
                />
              }
              {
                props?.description &&
                <Text
                  htmlTag='p'
                  textStyle='body-3'
                  color='neutral-600'
                >
                  { props.description }
                </Text>
              }
            </> }
          />
        </div>
      }

      {
        isButton( props.action ) &&
        <Button
          likeLink
          label={ props.action.label }
          ariaLabel={ props.description }
          action={ {
            graphql : props.action?.graphql,
            navigationType: props.action?.navigationType,
            config: {}
          } }
        />
      }

      { isOnlyIcon( props ) &&
        <Link_Huge
          secondary
          withHover
          action={ props.action }
        >
          <Icon
            className='NavigationLink__icon'
            size={ props.iconSize }
            name={ props.icon }
            title={ props.icon }
            { ...( props.description && { description:props.description } ) }
          />
        </Link_Huge>
      }
      <Modules
        modules={ props.modules }
        isLeftNavigation={ props.isLeftNavigation }

      />
    </div>
  );
} );

/**
 * Property type definitions
 *
 * @typedef NavigationLinkProps
 * @type {object}
 * @property {action} action - sets the action
 * @property {string} icon - sets the icon
 * @property {string} description - sets the description
 * @property {array} modules - sets the modules
 */
export const propTypes =  {
  action: PropTypes.object.isRequired,
  icon: PropTypes.string,
  description: PropTypes.string,
  modules: PropTypes.array
};
/**
  * Default values for passed properties
  * @type {object}
  * @property {string} textStyle - sets the TextStyle for text
  * @property {string} htmlTag - sets the htmlTag for text
  * @property {string} color - sets the color for text
  * @property {string} iconSize - sets the icon size for Icon
  */
export const defaultProps = {
  textStyle: 'body-2',
  htmlTag: 'span',
  color: 'black',
  iconSize: ''
};

NavigationLink.propTypes = propTypes;
NavigationLink.defaultProps = defaultProps;
NavigationLink.displayName = 'NavigationLink';

export default NavigationLink;
