/**
 * The ProductOffersDetail component is used to display a modal which list the offers available, it display a title and details of the offer provided to users for any product.
 *
 * @module views/components/ProductOffersDetail
 * @memberof -Common
 */
import './ProductOffersDetail.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import List from '@ulta/core/components/List/List';
import Markdown from '@ulta/core/components/Markdown/Markdown';
import Text from '@ulta/core/components/Text/Text';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';

import FindInStoreProduct from '../FindInStoreProduct/FindInStoreProduct';

/**
 * Represents a ProductOffersDetail component
 *
 * @method
 * @param {ProductOffersDetailProps} props - React properties passed from composition
 * @returns ProductOffersDetail
 */
export const ProductOffersDetail = function( props ){
  const { brandName, productName, variantTypeLabel, variantLabel, image, componentKey } = props;
  const { inflection } = useDeviceInflection();

  return (
    <div className='ProductOffersDetail'>
      <div className='ProductOffersDetail__content'>
        { inflection?.MOBILE &&
        <>
          <FindInStoreProduct
            brandName={ brandName }
            productName={ productName }
            typeLabel={ variantTypeLabel }
            valueLabel={ variantLabel }
            imageUrl={ image?.imageUrl }
            imageWidth={ 40 }
          />
        </>
        }
        <div className='ProductOffersDetail__title'>
          <Text
            textAlign='left'
            textStyle='title-5'
            htmlTag='h2'
          >
            { props.title }
          </Text>
        </div>
        <div className='ProductOffersDetail__offers'>
          <List display='Block'
            separator='Muted'
          >
            { props.offers && props.offers.map( ( offer, index )=>{
              return (
                <div className='ProductOffersDetail__markup'
                  key={ `${componentKey}-offer-detail-${index}` }
                >
                  { offer.title &&
                    <Text
                      textStyle='body-1'
                      fontWeight='bold'
                      htmlTag='h3'
                    >
                      { offer.title }
                    </Text>
                  }
                  { offer.subTitle &&
                    <Text
                      textStyle='body-2'
                    >
                      { offer.subTitle }
                    </Text>
                  }
                  <Markdown
                    bodyStyle='body-2'
                    cmsMarkdownContent={ offer?.body }
                  />
                  { offer.buttonAction &&
                  <Button
                    likeLink
                    label={ offer.buttonAction?.label }
                    ariaLabel={ offer.buttonAction?.label }
                  />
                  }
                  { offer.linkAction &&
                    <Link_Huge
                      compact
                      action={ offer.linkAction }
                      withHover
                      ariaLabel={ offer.linkAction?.label }
                    >
                      { offer.linkAction?.label }
                    </Link_Huge>
                  }
                </div>
              );
            } ) }
          </List>
        </div>
      </div>
    </div>
  );
};

/**
 * Property type definitions
 * @typedef ProductOffersDetailProps
 * @type {object}
 * @property {string} title - Set the title
 * @property {array} offers - Set the value for list of offer
 * @property {object.<{label: string, graphql: string }>} closeAccessibilityAction - Sets the close accessibility action
 * @property {string} brandName - Sets the brandName
 * @property {string} productName - Sets the productName
 * @property {string} variantTypeLabel - Sets the variantTypeLabel
 * @property {string} variantLabel - Sets the variantLabel
 * @property {object.<{imageUrl: string}>} image - Sets the imageUrl
 */

export const propTypes = {
  title: PropTypes.string,
  offers: PropTypes.arrayOf(
    PropTypes.shape( {
      title: PropTypes.string,
      subTitle: PropTypes.string,
      body: PropTypes.string,
      linkAction: PropTypes.shape( {
        label: PropTypes.string,
        url: PropTypes.string
      } ),
      buttonAction: PropTypes.shape( {
        label: PropTypes.string,
        url: PropTypes.string
      } )
    } )
  ),
  closeAccessibilityAction: PropTypes.shape( {
    label: PropTypes.string,
    graphql: PropTypes.string
  } ),
  brandName: PropTypes.string,
  productName: PropTypes.string,
  variantTypeLabel: PropTypes.string,
  variantLabel: PropTypes.string,
  image: PropTypes.shape( {
    imageUrl: PropTypes.string
  } )
};

ProductOffersDetail.propTypes = propTypes;

export default ProductOffersDetail;
