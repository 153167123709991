/**
 * Component to assign Validation colours to the text with icons
 *
 * @module ui/modules/Tag
 * @memberof -Common
 */
import React from 'react';

import PropTypes from 'prop-types';

import PaletteTag from 'web-palette/dist/components/Tag/Tag';

/**
 * Represents a Tag component
 *
 * @method
 * @param { TagProps} props - React properties passed from composition
 * @returns Tag
 */
export const Tag = function( props ){
  const {
    backgroundColor, // DXL only prop
    boldText,
    endTime,
    externalAction,
    icon,
    iconColor,
    message,
    size,
    startTime,
    style,
    textColor, // DXL only prop
    theme,
    timerMeta,
    timerType,
    variant
  } = props;

  const PalTag = PaletteTag.default || PaletteTag;

  return (
    <PalTag
      backgroundColor={ backgroundColor } // DXL only prop
      boldText={ boldText }
      endTime={ endTime }
      externalAction={ externalAction }
      icon={ icon }
      iconColor={ iconColor }
      message={ message }
      size={ size }
      startTime={ startTime }
      style={ style }
      textColor={ textColor } // DXL only prop
      theme={ theme }
      timerMeta={ timerMeta }
      timerType={ timerType }
      variant={ variant }
    />
  );
};

/**
 * Property type definitions
 * @typedef Tag
 * @type {object}
 * @property {string} backgroundColor - DXL only setting for backgroundColor
 * @property {string} boldText - bold text as title for compact utility tags
 * @property {string} endTime - end date and time for urgency tag (ISO-8601 representation)[ endTime = "2023-03-22T08:00:00.000Z"]
 * @property {object} externalAction - the externalAction object
 * @property {string} icon - name of the image to set infront of message
 * @property {string} iconColor - name of the icon color to set (default or grayscale)
 * @property {string} message - text passed as string
 * @property {string} size - size of urgency or icon tag (accepts compact, default, or large)
 * @property {string} startTime - start date and time for urgency tag [ startTime = "2023-03-22T08:00:00.000Z"]
 * @property {string} style - sets the style of the tag
 * @property {string} textColor - DXL only setting for text color
 * @property {object} timerMeta - string token object for urgency tag message
 * @property {string} timerType - type of urgency timer [3+ days, 2 days, 1 day, Hours, Extended, Upcoming, Timer]
 * @property {string} variant - the variant of tag [default, utility, utilityCompact, urgency, value]
 */

const propTypes = {
  /** DXL only setting for background color */
  backgroundColor: PropTypes.string,
  /** bold text as title (for compact utility tags only) */
  boldText: PropTypes.string,
  /** end date and time for urgency tag (ISO-8601 representation) [ endTime = "2023-03-28T08:00:00.000Z"] */
  endTime: PropTypes.string,
  /** Sets the primary action from an external source that contains business logic for icon tag */
  externalAction: PropTypes.object,
  /** name of the image to set infront of message */
  icon: PropTypes.string,
  /** icon color */
  iconColor: PropTypes.oneOf( [
    'default',
    'grayscale'
  ] ),
  /** text passed as string */
  message: PropTypes.string,
  /** size of urgency tag (accepts large only) */
  size: PropTypes.oneOf( [
    'compact',
    'default',
    'large'
  ] ),
  /** start date and time for urgency tag [ startTime = "2023-03-22T08:00:00.000Z"] */
  startTime: PropTypes.string,
  /**
   * Determines style of tag
   * - **default:** The default tag style
   * - **white:** The white tag style
   * - **transparent:** The transparent tag style
   * - **validation:** The validation tag style
   * - **notification:** The notification tag style
   * - **warning:** The warning tag style
   * - **error:** The error tag style
   */
  style: PropTypes.oneOf( [
    'default',
    'white',
    'transparent',
    'validation',
    'notification',
    'warning',
    'error'
  ] ),
  /** DXL only setting for text color */
  textColor: PropTypes.string,
  /** string token object for urgency tag message */
  timerMeta: PropTypes.object,
  /**
   * Determines the type of urgency timer
   * - **3+ days:** The 3+ days style
   * - **2 days:** The 2 days style
   * - **1 day:** The 1 day style
   * - **Hours:** The Hours style
   * - **Extended:** The Extended style
   * - **Upcoming:** The Upcoming style
   * - **Timer:** The Timer style
   */
  timerType: PropTypes.oneOf( [
    '3+ days',
    '2 days',
    '1 day',
    'Hours',
    'Extended',
    'Upcoming',
    'Timer'
  ] ),
  /**
   * Determines variation of tag
   * - **default:** The default tag style
   * - **utility:** The utility tag style
   * - **utilityCompact:** The utility compact tag style
   * - **urgency:** The urgency tag style
   * - **value:** The value tag style
   */
  variant: PropTypes.oneOf( [
    'default',
    'utility',
    'utilityCompact',
    'urgency',
    'value'
  ] )
};

/**
 * Default values for passed properties
 *
 * @type {object}
 * @property {string} theme='default' - sets the default variant for the theme to set the colors from DXL
 * @property {string} variant='default' - sets the default variant for the Tag
 */
export const defaultProps =  {
  theme: 'default',
  variant: 'default'
};

Tag.propTypes = propTypes;
Tag.defaultProps = defaultProps;

export default Tag;