/**
 * Common ProductRailHorizontal component used by other modules as a reusable component which returns the ui for a product carousel.
 *
 * @module views/Organisms/ProductRailHorizontal
 * @memberof -Common
 */
import './ProductRailHorizontal.scss';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import React, { useCallback, useRef } from 'react';

import PropTypes from 'prop-types';
import SwiperCore, { A11y, Keyboard, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import Button from '@ulta/core/components/Button/Button';
import Image from '@ulta/core/components/Image/Image';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { hasItems } from '@ulta/core/utils/array/array';
import { constants } from '@ulta/core/utils/constants/constants';
import { handleSwiperImpressionEvent } from '@ulta/core/utils/datacaptureAggregator/datacaptureAggregator';

import { useProductRailHorizontalContext } from '@ulta/providers/ProductRailHorizontalProvider/ProductRailHorizontalProvider';

import carouselUtils, { handleSwiperSlideChange } from '@ulta/utils/carouselUtils/carouselUtils';

import ProductHighlighter from '../ProductHighlighter/ProductHighlighter';
import * as utils from './ProductRailHorizontal';

SwiperCore.use( [Navigation, Keyboard, A11y] );
let swiperCounter = 1;

/**
 * Represents a ProductRailHorizontal component
 *
 * @method
 * @param {ProductRailHorizontalProps} props - React properties passed from composition
 * @returns ProductRailHorizontal
 */
const ProductRailHorizontal = ( props ) => {
  const railRef = useRef( null );
  const carouselRef = useRef( null );
  const { setVisibleSlidesIndexes, visibleSlidesIndexes } = useProductRailHorizontalContext();

  const { breakpoint } = useDeviceInflection();

  const {
    componentKey,
    countLabel,
    items,
    nextAccessibility,
    nextClickAction,
    previousAccessibility,
    previousClickAction,
    spotlight,
    title,
    subtitle,
    viewAllAction,
    carouselAccessibility,
    carouselDetailsAccessibility
  } = props;

  const isHighlighter = !!(
    spotlight &&
    spotlight.eyebrow &&
    spotlight.title &&
    spotlight.subtitle &&
    spotlight.graphicElement?.graphicImage?.imageUrl
  );

  const isSmallDevice = breakpoint.isSmallDevice();
  let productCards = items;

  if( isHighlighter && isSmallDevice ){
    productCards = items.slice( 1 );
  }

  const calculateSlideVisibility = useCallback(
    utils.composeCalculateVisibleSlides( { isHighlighter, visibleSlidesIndexes } )
    , [visibleSlidesIndexes, isHighlighter] );

  useIntersectionObserver(
    railRef,
    {
      root: props.root,
      rootMargin: props.rootMargin,
      threshold: props.threshold
    },
    handleSwiperImpressionEvent( props, carouselRef, isHighlighter )
  );

  swiperCounter += 1;

  if( !hasItems( items ) ){
    return null;
  }

  return (
    <div className='ProductRailHorizontal'>
      <div className='ProductRailHorizontal__content'
        ref={ railRef }
      >
        <div className='ProductRailHorizontal__header'>
          <div className='ProductRailHorizontal__headerTitle'>
            <Text textStyle='title-6'
              htmlTag='h2'
            >
              { title }
            </Text>
            { viewAllAction?.url && viewAllAction?.label && (
              <Link_Huge withHover={ true }
                action={ props.viewAllAction }
              >
                { viewAllAction.label }
              </Link_Huge>
            ) }
          </div>
          { subtitle && (
            <div className='ProductRailHorizontal__subTitle'>
              <Text textStyle='body-2'
                htmlTag='p'
              >
                { subtitle }
              </Text>
            </div>
          ) }
        </div>
        <div className='ProductRailHorizontal__countLabel'>
          <Text textStyle='body-2'
            htmlTag='p'
            color='neutral-600'
          >
            { items.length > 1 ? `${countLabel}` : '\u00A0' }
          </Text>
        </div>
        <div className='ProductRailHorizontal_Carousel'
          role='region'
          aria-label={ carouselUtils.handleTokenizedString( carouselAccessibility, [title] ) }
        >
          <p className='sr-only'>{ carouselUtils.handleTokenizedString( carouselDetailsAccessibility, [title] ) }</p>
          { isSmallDevice && isHighlighter && (
            <div className='ProductRailHorizontal__highlightedProduct'>
              <ProductHighlighter { ...items[0] }
                spotlight={ spotlight }
                componentKey={ componentKey }
              />
            </div>
          ) }
          <div className={ `ProductRailHorizontal-Pagination ProductRailHorizontal-Pagination-${swiperCounter}` }>
            <Button
              variant='navigation'
              iconImage='CaretBack'
              iconSize='lg'
              className='ProductRailHorizontal-Pagination__button ProductRailHorizontal-Pagination__button--back'
              ariaLabel={ previousAccessibility }
              ariaHiddenIcon={ true }
              onClick={ handleSwiperSlideChange(
                null,
                previousClickAction,
                carouselRef,
                handleSwiperImpressionEvent( props, carouselRef, isHighlighter ),
                railRef?.current
              ) }
            />

            <Button
              variant='navigation'
              iconImage='CaretForward'
              iconSize='lg'
              className={ 'ProductRailHorizontal-Pagination__button ProductRailHorizontal-Pagination__button--forward' }
              ariaLabel={ nextAccessibility }
              ariaHiddenIcon={ true }
              onClick={ handleSwiperSlideChange(
                nextClickAction,
                null,
                carouselRef,
                handleSwiperImpressionEvent( props, carouselRef, isHighlighter ),
                railRef?.current
              ) }
            />
          </div>

          <Swiper
            ref={ carouselRef }
            watchOverflow={ true }
            watchSlidesProgress={ true }
            watchSlidesVisibility={ true }
            freeMode={ {
              minimumVelocity: 0.01,
              momentum: true,
              momentumBounce: true,
              momentumBounceRatio: 1.2,
              momentumRatio: 1.2,
              momentumVelocityRatio: 1.2,
              sticky: true
            } }
            onSwiper={ ( swiper ) => setVisibleSlidesIndexes( swiper?.visibleSlidesIndexes ) }
            onActiveIndexChange={ ( swiper ) => setVisibleSlidesIndexes( swiper?.visibleSlidesIndexes ) }
            speed={ 600 }
            preloadImages={ true }
            spaceBetween={ 16 }
            navigation={ {
              prevEl: `.ProductRailHorizontal-Pagination-${swiperCounter} .ProductRailHorizontal-Pagination__button--back`,
              nextEl: `.ProductRailHorizontal-Pagination-${swiperCounter} .ProductRailHorizontal-Pagination__button--forward`,
              disabledClass: 'ProductRailHorizontal-Pagination__button--disabled',
              hiddenClass: 'ProductRailHorizontal-Pagination__button--hidden'
            } }
            breakpoints={ {
              1200: {
                slidesPerView: 4,
                slidesPerGroup: 4
              },
              992: {
                slidesPerView: isHighlighter ? 3 : 4,
                slidesPerGroup: isHighlighter ? 3 : 4
              },
              768: {
                slidesPerView: 3,
                slidesPerGroup: 3
              },
              0: {
                slidesPerView: 1.5,
                slidesPerGroup: 1
              }
            } }
          >
            { !isSmallDevice && isHighlighter && (
              <SwiperSlide>
                <div className='ProductRailHorizontal__highlightedContent'>
                  <div className='ProductRailHorizontal__contentWrapper'>
                    <div className='ProductRailHorizontal__eyebrow'>
                      <Text htmlTag='p'
                        textStyle='eyebrow'
                        textAlign='right'
                      >
                        { spotlight.eyebrow }
                      </Text>
                    </div>
                    <div className='ProductRailHorizontal__title'>
                      <Text htmlTag='p'
                        textStyle='title-6'
                        textAlign='right'
                      >
                        { spotlight.title }
                      </Text>
                    </div>
                    <div className='ProductRailHorizontal__subtitle'>
                      <Text htmlTag='p'
                        textStyle='subtitle-2'
                        textAlign='right'
                      >
                        { spotlight.subtitle }
                      </Text>
                    </div>
                  </div>
                  <div className='ProductRailHorizontal__horizontalLine'>
                    <Image
                      src={ spotlight.graphicElement.graphicImage.imageUrl }
                      metaData={ spotlight.graphicElement.graphicImage.metaData }
                    />
                  </div>
                </div>
              </SwiperSlide>
            ) }
            { productCards?.length > 0 &&
                productCards.map( ( product, index ) => {
                  const { isFocusable, slideIndex } = calculateSlideVisibility( index );

                  return (
                    <SwiperSlide key={ index }
                      aria-hidden={ !isFocusable }
                    >
                      <div key={ product.skuId + '_' + index }>
                        <AsyncComponent
                          moduleName='ProductCard'
                          componentKey={ componentKey }
                          // TODO: this componentLastUpdated needs to be refactored -- https://ulta.atlassian.net/browse/DSOTF2-109266
                          componentLastUpdated={ componentKey }
                          skuId={ product.skuId }
                          productId={ product.productId }
                          action={ product.action }
                          additionalOffersText={ product.additionalOffersText }
                          altImage={ product.altImage }
                          badge={ product.badge }
                          bookmarkAccessibility={ product.bookmarkAccessibility }
                          bookmarked={ product.bookmarked }
                          brandName={ product.brandName }
                          image={ product.image?.imageUrl }
                          kitPrice={ product.kitPrice }
                          listPrice={ product.listPrice }
                          productName={ product.productName }
                          promoText={ product.promoText }
                          rating={ product.rating }
                          reviewCount={ product.reviewCount }
                          salePrice={ product.salePrice }
                          variantLabel={ product.variantLabel }
                          hoverState={ false }
                          displayBookmark={ false }
                          addToBagAction={ product.addToBagAction }
                          viewOptionAction={ product.viewOptionAction }
                          availability={ product.availability }
                          carouselIndex={ slideIndex }
                          { ...( props.reviewAccessibilityLabel && {
                            reviewAccessibilityLabel: props.reviewAccessibilityLabel
                          } ) }
                        />
                      </div>
                    </SwiperSlide>
                  );
                } )
            }
          </Swiper>
        </div>
      </div>
    </div>
  );
};


/**
 * composeCalculateVisibleSlides function to calculate slide visibility for ADA compliance
 * @method
 * @param {object} data - Data containing necessary properties to calculate visibility.
 * @param {boolean} data.isHighlighter - Determines if this is a highlighter ProductRail
 * @param {Array<number>} data.visibleSlidesIndexes - Array of indexes representing visible slides in the carousel.
 * @returns {object} - An object containing the slide index and whether it is focusable.
 */
export const composeCalculateVisibleSlides = ( data ) => ( index ) => {
  const { isHighlighter, visibleSlidesIndexes } = data || {};
  // if isHighlighter we need to skip one slide to calculate visible slides
  const slideIndex = isHighlighter ? index + 1 : index;
  const isVisibleInCarousel = visibleSlidesIndexes?.length &&
    visibleSlidesIndexes.includes( slideIndex );
  const isFocusable = isVisibleInCarousel !== false;

  return { isFocusable, slideIndex };
};

/**
 * Default values for passed properties
 * @type {object}
 * @property {string} previousAccessibility='' - The default value of the previousAccessibility is empty string.
 * @property {string} nextAccessibility='' - The default value of the nextAccessibility is empty string.
 * @property {string} title='' - The default value of the title is empty string.
 * @property {string} countLabel='' - The default value of the count label is empty string.
 * @property {boolean} arrows=false - The default value for arrows is set to false.
 * @property {number} slidesToSlide=4 - The default value for slidesToSlide is set to 4.
 * @property {array} items=[] - The default value for items is set to empty array.
 */
export const defaultProps = {
  previousAccessibility: '',
  nextAccessibility: '',
  title: '',
  countLabel: '',
  arrows: false,
  slidesToSlide: 4,
  items: [],
  ...constants.INTERSECTION_OBSERVER_OPTIONS
};

/**
* Property type definitions
* @typedef ProductRailHorizontalProps
* @type {object}
* @property {string} previousAccessibility - Sets the aria label for previous button.
* @property {string} nextAccessibility - Sets the aria label for next button.
* @property {string} title - Sets the title for productRail list.
* @property {string} subtitle - Sets the subtitle for productRail list.
* @property {string} countLabel - Sets the count label for productRail list.
* @property {boolean} arrows - Sets whether to show or hide default arrows on carousel.
* @property {number} slidesToSlide - Sets the number of slides to slide when cliked on next or previous button.
* @property {Array.<{promoText: string, reviewCount: Number, rating: Number, brandName: string, productName: string, variantLabel: string, badge: string, image: string, availability: array, addToBagAccessibility: string, additionalOffersText: string, altImage: string, bookmarkAccessibility: string, bookmarked: boolean, kitPrice: string, listPrice: string, salePrice: string, skuId: string}>} products - List of products to loop over and display in carousel.
* @property {object} viewAllAction - This is the action object with action label and url.
* @property {string} carouselDetailsAccessibility - Value for aria label for carousel
* @property {string} carouselAccessibility - Value for aria label for product rail
* @property {string} carouselCardAccessibility - Value for the product card aria label
* @property {string} reviewAccessibilityLabel - Value for the rating aria label
* @property {number} componentKey - Sets the component key
*/
export const propTypes = {
  /**
  * The prop that holds the aria label for previous button.
  */
  previousAccessibility: PropTypes.string,
  /**
  * The prop that holds the aria label for next button.
  */
  nextAccessibility: PropTypes.string,
  /**
  * The prop that holds title for the carousel.
  */
  title: PropTypes.string,
  /**
  * The prop that holds subtitle for the carousel.
  */
  subtitle: PropTypes.string,
  /**
  * The prop that holds the count label for the product list.
  */
  countLabel: PropTypes.string,
  /**
  * The prop that decides to render default arrows on carousel.
  */
  arrows: PropTypes.bool,
  /**
  * The prop that decides number of slides to slide when cliked on next or previous button.
  */
  slidesToSlide: PropTypes.number,
  /**
  * The prop that decides to show aria-label.
  */
  reviewAccessibilityLabel: PropTypes.string,
  /**
  * The prop that holds the list of products to render.
  */
  items: PropTypes.arrayOf(
    PropTypes.shape( {
      promoText: PropTypes.string,
      reviewCount: PropTypes.number,
      rating: PropTypes.number,
      brandName: PropTypes.string,
      productName: PropTypes.string,
      variantLabel: PropTypes.string,
      badge: PropTypes.string,
      image: PropTypes.object,
      availability: PropTypes.arrayOf( PropTypes.string ),
      listPrice: PropTypes.string,
      salePrice: PropTypes.string,
      kitPrice: PropTypes.string,
      bookmarkAccessibility: PropTypes.string,
      addToBagAccessibility: PropTypes.string,
      additionalOffersText: PropTypes.string,
      altImage: PropTypes.string,
      bookmarked: PropTypes.bool,
      skuId: PropTypes.string
    } )
  ),
  /**
  * This is the action with label and url.
  */
  viewAllAction: PropTypes.shape( {
    label: PropTypes.string,
    url: PropTypes.string

  } ),
  /** The prop that holds the action for the next button. */
  nextClickAction: PropTypes.object,
  /** The prop that holds the action for the previous button. */
  previousClickAction: PropTypes.object,
  /** The prop that holds  aria label for carousel  */
  carouselDetailsAccessibility: PropTypes.string,
  /** The prop that holds  aria label for carousel  */
  carouselAccessibility: PropTypes.string,
  /** The prop that holds  aria label for product card  */
  carouselCardAccessibility: PropTypes.string,
  /** Sets the component key */
  componentKey: PropTypes.number
};

ProductRailHorizontal.propTypes = propTypes;
ProductRailHorizontal.defaultProps = defaultProps;

export default ProductRailHorizontal;